module.exports = [{
      plugin: require('/zeit/29b21e0a/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/29b21e0a/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"wrapperStyle":"position: relative; margin: auto -33%;"},
    },{
      plugin: require('/zeit/29b21e0a/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/29b21e0a/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
